/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI780M
 * 화면 설명  : 가입설계지원현황
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="설계진행현황" :topButton="false" @on-scroll-bottom="fn_ScrollBottomHandler">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_goMain()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '설계진행현황' : '설계진행현황'}}</div>

              <div slot="action" @click="fn_OpenMSPPI783P()">
                <mo-icon>search</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt20">
                <div class="number-list__wrap"><!-- 2024.03.26 class mt20 삭제 -->
                  <span v-for="(item, idx) in vEntplStasList" :key="idx">
                    <em>{{item.title}}</em>
                    <span>{{item.value}}</span>
                  </span>
                </div>
              </ur-box-container>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <!-- sum -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum ">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title tooltip-num__wrap">
                    <span class="sum">총 <strong class="crTy-blue3">{{vEntplPrgCnt}}</strong> 건</span>
                    <!-- 2024.04.04 툴팁 원복 -->
                    <span class="ns-tool-tip">
                      <mo-icon id="location2" icon="msp-tool-tip" />
                      <mo-tooltip target-id="location2" class="tool-tip-box img-type-1">
                        <b class="fs14rem mb10">진행상태</b>
                        <ul class="badge-manual__wrap">
                          <li>
                            <em class="t01 fs14rem">가입설계 요청</em>
                            <span>설계지원 진행 전</span>
                          </li>
                          <li>
                            <em class="t02 fs14rem">가입설계</em>
                            <span class="fs14rem">가입설계 저장 / 선심사 완료 / 청약서 저장</span>
                          </li>
                          <li>
                            <em class="t03 fs14rem">발행</em>
                            <span class="fs14rem">지류청약 발행 / 모바일청약 발송</span>
                          </li>
                          <!-- 2024.04.04 청약상태 삭제
                          <li>
                            <em class="t04 fs14rem">청약</em>
                            <span class="fs14rem">청약접수 / 출금완료 / 심사미결 / 신계약 성립</span>
                          </li>
                          -->
                        </ul>
                      </mo-tooltip>
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- // sum end -->
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="con-area">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list mb50 types2">
                <mo-list :list-data="vEntplPrgList">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <!-- 2024.04.04 리스트 컨텐츠 펼쳐져있는 상태로 보이게 수정 start -->
                      <msp-expand msp-expand btn-area-first title-first expand-only-btn expanded class="mo-list-expand">
                        <template #title>
                          <div class="list-item__contents">
                            <div class="list-item__contents__title">
                              <span class="badge-req name txtSkip" :class="`t0${item.badge1}`">{{item.state}}</span>
                            </div>
                            <div class="list-item__contents__info">
                              <div class="info_box">
                                <span class="name">{{item.fnEnoNm}}  </span>
                                <span class="num">{{item.fpEno}}</span>
                              </div>
                            </div>
                            <!-- 2024.04.04 지사명 삭제
                            <div>
                              <span class="branch">{{item.data3}}</span>
                            </div>
                            -->
                          </div>
                        </template>
                        <template #btn>
                          <mo-button class="link-arrow down"></mo-button>
                        </template>
                        <!-- 아코디언 컨텐츠 -->
                        <template #content>
                          <div class="list-item-detail">
                            <div class="contents-row">
                              <span class="name fexTy5 mb7">
                                <mo-badge 
                                  class="custom-badge-md mr10"
                                  shape="status" 
                                  :class="{
                                    'blue2' : item.badge2 === '피보험자', 
                                    'blue' : item.badge2 === '종피', 
                                    'green' : item.badge2 === '계약자', 
                                    'gray2' : item.badge2 === '친권자 1' || item.badge2 === '친권자 2'}"
                                  >
                                  {{item.badge2}}
                                </mo-badge> 
                                {{item.custNm}}
                              </span>
                            </div>
                            <div class="contents-row txt-wrap">
                              <span class="tit">상품명</span>
                              <span>{{item.prdtNm}}</span>
                            </div>
                            <div class="contents-row txt-wrap">
                              <span class="tit">합계보험료</span>
                              <span>{{item.prm}}</span>
                            </div>
                            <div class="contents-row txt-wrap">
                              <span class="tit">청약서 발행</span>
                              <span>{{item.ofrfmPblYmd}}</span>
                            </div>
                            <div class="contents-row txt-wrap">
                              <span class="tit">요청사항</span>
                              <span>{{item.reqDtlCntnt}}</span>
                            </div>
                          </div>
                        </template>
                        <!-- 아코디언 컨텐츠 end -->
                      </msp-expand>
                      <!-- 2024.04.04 리스트 컨텐츠 펼쳐져있는 상태로 보이게 수정 end -->
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </ur-box-container>
            <!-- Content 영역 end -->
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /*******************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   *******************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  import piCommonUtil from '@/ui/pi/common/piCommonUtil'
  import MSPPI783P from '@/ui/pi/MSPPI783P'
  export default {
    /*******************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     *******************************************************************************/
    name: "MSPPI780M",
    screenId: "MSPPI780M",
    components: {
      "MSPPI783P" : MSPPI783P // 설계진행현황 조회
    },
    /*******************************************************************************
     * Life Cycle 함수 정의 영역
     * - 각단계별 예약 함수에 맞게 스크립트 작성
     *******************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      //GA매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

      // 설계사는 접근불가
      if(this.vGssAuthYN != 'Y') {
        this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: 'GA매니저 전용화면입니다.',
          title_pos_btn: '확인'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
          },
          onPopupClose: () => {
            this.fn_goMain()
            this.$bottomModal.close(this.alertPop);
            }
          }
        })
      }

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      
      this.vReqYmd = this.vToYmd // 시작일자 : 현재년월
      this.vCnsltId = this.vUserInfo.userId // 컨설턴트ID : 사용자ID
      
      // 설계요청 미완료, 청약서 발행 건수 조회
      this.fn_selEntplVuchCnt()

      // 설계진행현황 조회
      this.fn_SrchEntplPrgPnsta()
    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /*******************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     *******************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        vUserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
        vGssAuthYN: '', //GA매니저 권한
        vToYmd: moment(new Date()).format('YYYYMM'), // 현재년월
        vReqYmd: '', // 요청년월
        vCnsltId: '', // 요청컨설턴트
        vNextKey: 0, // 다음조회순번
        vReadCount: 20, // 조회건수
        vEntplPrgCnt: 0, // 설계진행 건수
        vTotalCnt: 0, // 설계진행 총 건수
        vEntplStasList: [
          {title: '설계요청', value: '0'},
          {title: '청약서발행', value: '0'}
        ],
        vEntplPrgList: []
      };
    },
    /*******************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     *******************************************************************************/
    computed: {
      
    },
    /*******************************************************************************
     * 사용자 함수 정의 영역
     *******************************************************************************/
    methods: {
      /*******************************************************************************
       * Function명: fn_goMain
       * 설명: 메인으로 이동
       *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      /******************************************************************************
       * Function명 : fn_OpenMSPPI783P
       * 설명       : 설계진행현황 조회 팝업 호출
       ******************************************************************************/
      fn_OpenMSPPI783P() {
        this.popup783 = this.$bottomModal.open(MSPPI783P, {
          properties: {
          },
          listeners: {
            onPopupConfirm: (pData) => {
              
              // 건수 초기화
              this.vEntplStasList[0].value = 0 // 설계요청 미완료 건수
              this.vEntplStasList[1].value = 0 // 청약서 발행 건수
              
              // 목록 초기화
              this.vEntplPrgCnt = 0
              this.vEntplPrgList = []
              
              // NextKey 초기화
              this.vNextKey = 0

              this.vReqYmd = pData.reqYmd // 선택 조회년월
              this.vCnsltId = pData.cnsltId // 선택 컨설턴트ID

              // 설계요청 미완료, 청약서 발행 건수 조회
              this.fn_selEntplVuchCnt()

              // 설계진행현황 조회
              this.fn_SrchEntplPrgPnsta()
              
              this.$bottomModal.close(this.popup783)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popup783)
            }
          }
        },
        {
          properties : {noHeader : true}
        })
      },
      /******************************************************************************
       * Function명 : fn_selEntplVuchCnt
       * 설명       : 설계요청 미완료, 청약서 발행 건수 조회
       ******************************************************************************/
      fn_selEntplVuchCnt() {
        let lv_Vm = this
        const trnstId = 'txTSSPI08S3'
        const auth = 'S'
        let pParams = {}
        
        let dma_GAM00010DVO = {}
        dma_GAM00010DVO.fpEno = lv_Vm.vUserInfo.userId // FP사번 : 사용자ID(GA매니저) - GA세션처리용
        
        let dma_input = {}
        dma_input.mngerId = lv_Vm.vUserInfo.userId // 매니저ID : 사용자ID(GA매니저)
        dma_input.reqYmd = lv_Vm.vReqYmd // 조회년월

        pParams.dma_input = dma_input
        pParams.dma_GAM00010DVO = dma_GAM00010DVO
        pParams.fnScCd = '11', // 기능구분(11:설계지원 미완료, 청약서발행 건수 조회)
        
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (!piCommonUtil.isEmpty(response.body)) {
            let result = response.body.dma_input

            lv_Vm.vEntplStasList[0].value = result.entplNoCnt // 설계요청 미완료 건수
            lv_Vm.vEntplStasList[1].value = result.vuchNoCnt // 청약서 발행 건수
          }

        }).catch(function (error) {
          window.vue.error(error)
        })
        
      },
      /******************************************************************************
       * Function명 : fn_SrchEntplPrgPnsta
       * 설명       : 설계진행현황 조회
       ******************************************************************************/
      fn_SrchEntplPrgPnsta() {
        let lv_Vm = this
        const trnstId = 'txTSSPI08S4'
        const auth = 'S'
        let pParams = {}
        let pFpEno = lv_Vm.vCnsltId === lv_Vm.vUserInfo.userId ? '' : lv_Vm.vCnsltId

        let dma_GAM00010DVO = {}
        dma_GAM00010DVO.fpEno = lv_Vm.vUserInfo.userId // FP사번 : 사용자ID(GA매니저) - GA세션처리용

        let dma_input = {}
        dma_input.mngerId = lv_Vm.vUserInfo.userId // 매니저ID : 사용자ID(GA매니저)
        dma_input.fpEno = pFpEno // 컨설턴트사번
        dma_input.reqYmd = lv_Vm.vReqYmd // 요청일자
        dma_input.readCount = lv_Vm.vReadCount // 조회건수
        dma_input.nextKey = lv_Vm.vNextKey // 다음조회순번
        

        pParams.dma_input = dma_input
        pParams.dma_GAM00010DVO = dma_GAM00010DVO
        pParams.fnScCd = '02', // 기능구분(02:가입설계지원요청관리 목록 조회)
        
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (!piCommonUtil.isEmpty(response.body)) {

            let result = response.body.dma_input
            let resultList = response.body.dtl_ReqMngVO

            resultList.forEach((item) => {
              // 1 : 가입설계 요청
              // 2 : 가입설계
              // 3 : 발행
              if(!piCommonUtil.isEmpty(item.vuchNo) && !piCommonUtil.isEmpty(item.ofrfmPblYmd)) {
                item.badge1 = '3'
                item.state = '발행'
              }
              else if(!piCommonUtil.isEmpty(item.entplNo)) {
                item.badge1 = '2'
                item.state = '가입설계'
              }
              else {
                item.badge1 = '1'
                item.state = '가입설계 요청'
              }

              item.badge2 = '피보험자'
              item.prm = (piCommonUtil.isEmpty(item.prm) || item.prm === '0') ? '' : lv_Vm.$bizUtil.numberWithCommasByNumber(item.prm) + '원'
              item.ofrfmPblYmd = lv_Vm.$commonUtil.dateDashFormat(item.ofrfmPblYmd)

              lv_Vm.vEntplPrgList.push(item)
            })

            lv_Vm.vNextKey = result.nextKey.trim()
            lv_Vm.vTotalCnt = result.totalCnt
            lv_Vm.vEntplPrgCnt = lv_Vm.vEntplPrgList.length
          }

        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명  : fn_ScrollBottomHandler
       * 설명        : 스크롤 하단위치시 리스트 추가 조회
       ******************************************************************************/
      fn_ScrollBottomHandler() {
        
        if(!piCommonUtil.isEmpty(this.vNextKey)) {
          // 설계진행현황 조회
          this.fn_SrchEntplPrgPnsta()
        }
        
      },

    }
  };
</script>
<style scoped>
</style>