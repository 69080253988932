/*
 * 업무구분   : 설계청약
 * 화면 명    : MSPPI783P
 * 화면 설명  : 설계진행현황 조회
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="설계지원현황 조회" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="popupClose">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '설계지원현황 조회' : '설계지원현황 조회'}}</div>
              <div slot="action" class="icon-wrapper" @click="popupClose">
                <mo-icon icon="close" class="fs26rem svg-closeTy1">close</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
              <span class="contain-title must">조회기간</span>
              <div class="add-date tit-inline__wrap">
                <!-- 2024.03.27 month-picker로 교체
                <mo-date-picker bottom v-model="vStrYmd" placeholder="선택일" class="ns-date-picker" />
                <span class="tilde"></span>
                <mo-date-picker bottom v-model="vEndYmd" :min-date="vMinEndYmd" :max-date="vMaxEndYmd" placeholder="선택일" class="ns-date-picker" />
                -->
                <mo-month-picker class="full" v-model="vReqYm" placeholder="선택월"/>
              </div>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
              <div class="info-title-wrap">
                <span class="contain-title">컨설턴트</span>
              </div>
              <ur-box-container alignV="start" componentid="" direction="column">
                <!-- GA 매니저일때만 변경 가능 -->
                <mo-text-field  class="form-input-name full" v-model="vCnsltInfo" @click="fn_OpenMSPBC410P()" @click-icon="fn_OpenMSPBC410P()" :disabled="vGssAuthYN != 'Y'" :searchable="vGssAuthYN === 'Y'" :clearable="vGssAuthYN === 'Y'" underline placeholder="전체" />
              </ur-box-container>
            </ur-box-container>
            <!-- 2024.03.26 설계지원현황 조회- 고객명, 진행상태 항목삭제-->
            <!--
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
              <div class="info-title-wrap">
                <span class="contain-title">고객명</span>
              </div>
              <ur-box-container alignV="start" componentid="" direction="column">
                <mo-text-field  class="form-input-name full" v-model="vCustNm" @click="fn_OpenMSPCM129P()" @click-icon="fn_OpenMSPCM129P()" searchable underline placeholder="전체" />
              </ur-box-container>
            </ur-box-container>
            
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
              <div class="info-title-wrap">
                <span class="contain-title">진행상태</span>
              </div>
              <ur-box-container alignV="start" componentid="" direction="column">
                <msp-bottom-select underline closeBtn class="ns-dropdown-sheet" v-model="vPrgStat" :items="vPrgStatList" placeholder="전체" />
              </ur-box-container>
            </ur-box-container>
            -->
            <template>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_confirmYes">조회</mo-button>
                </div>
              </ur-box-container>
            </template>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /*******************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   *******************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPBC410P from '@/ui/bc/MSPBC410P'
  export default {
    /*******************************************************************************
     * Vue 파일 속성 정의 영역
     * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
     * - screenId : 화면ID
     * - components : UI TAG 컴포넌트 정의
     *******************************************************************************/
    name: "MSPPI783P",
    screenId: "MSPPI783P",
    components: {
      "MSPBC410P" : MSPBC410P // 컨설턴트선택 팝업
    },
    /***********************************************************************************
     * 팝업일때 팝업 활성 형태 속성 
     * - 풀 팝업 형태 일때 mode 값 설정
     ***********************************************************************************/
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /*******************************************************************************
     * Life Cycle 함수 정의 영역
     * - 각단계별 예약 함수에 맞게 스크립트 작성
     *******************************************************************************/
    /** Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선 */
    created() {
      //GA매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /** Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선 */
    mounted() {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()

      // 컨설턴트 기본은 사용자
      this.vCnsltId = this.vUserInfo.userId
      this.vCnsltNm = this.vUserInfo.userNm
      this.vCnsltInfo = this.vUserInfo.userNm + "(" + this.vUserInfo.userId + ")"
    },
    /** Step-8 인스턴스가 Remove 된 후 호출 */
    destroyed () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.popupClose)
    },
    /*******************************************************************************
     * 화면 전역 변수 선언 영역
     * - UI 바인딩 및 스크립트 화면 전역변수 정의
     *******************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        vGssAuthYN: '', //GA매니저 권한
        vUserInfo: this.getStore('userInfo').getters.getUserInfo, // 사용자 정보
        vReqYm: [moment(new Date()).format('YYYY'), moment(new Date()).format('MM')], // 선택년월
        vCnsltId: '', // 컨설턴트ID
        vCnsltNm: '', // 컨설턴트명
        vCnsltInfo: '' // 컨설턴트정보
      };
    },
    /*******************************************************************************
     * Computed 함수 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
     *   지정 함수는 반드시 리턴 하는 함수로 작성
     *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
     *******************************************************************************/
    computed: {
      
    },
    /***********************************************************************************
     * watch 정의 영역
     * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
     *   (변수에 대한 값 변경시 반응형 콜백 처리)
     *   ex) data 영역 정의한 익명변수 userNm 
     *       userNm : function() { this.message + ' 님 안녕하세요!'}
     ***********************************************************************************/
    watch: {
      vCnsltInfo () {
        setTimeout(() => {
          // 컨설턴트 초기화시 본인 사번으로 셋팅
          if( this.vCnsltInfo === '' ) {
            this.vCnsltId = this.vUserInfo.userId
            this.vCnsltNm = this.vUserInfo.userNm
            this.vCnsltInfo = this.vUserInfo.userNm + "(" + this.vUserInfo.userId + ")"
          }
        }, 10)
      }
    },
    /*******************************************************************************
     * 사용자 함수 정의 영역
     *******************************************************************************/
    methods: {
      /*******************************************************************************
       * Function명: popupClose
       * 설명: 팝업닫기
       *******************************************************************************/
      popupClose () {
        this.$emit('onPopupClose')
      },
      /******************************************************************************
      * Function명 : fn_OpenMSPBC410P
      * 설명       : 컨설턴트선택 팝업 호출
      ******************************************************************************/
      fn_OpenMSPBC410P () {
        let lv_Vm = this
        // GA매니저 일때만 팝업 호출
        if(lv_Vm.vGssAuthYN != 'Y') {
          return
        }

        lv_Vm.popup410 = lv_Vm.$bottomModal.open(MSPBC410P, {
          properties: {
          },
          listeners: {
            // 확인
            onPopupConfirm: (pData) => {
              lv_Vm.vCnsltId = pData.eplyNo // 컨설턴트ID
              lv_Vm.vCnsltNm = pData.gssEplyNm // 컨설턴트명
              lv_Vm.vCnsltInfo = pData.gssEplyNm + "(" + pData.eplyNo + ")"

              //고객검색시 컨설턴트의 고객을 조회하기 위한 컨설턴트정보 셋팅
              lv_Vm.getStore('cmStore').dispatch('GSS_EPLY_INFO', pData)

              lv_Vm.$bottomModal.close(lv_Vm.popup410)
            },
            // 닫기
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup410)
            }
          }
        },
        {
          properties : {noHeader : true}
        })
      },
      /******************************************************************************
      * Function명 : fn_confirmYes
      * 설명       : 조회
      ******************************************************************************/
      fn_confirmYes () {
        let pParams = {}
        
        pParams.reqYmd = this.vReqYm[0] + this.$bizUtil.lpad(this.vReqYm[1], 2, '0') // 요청년월
        pParams.cnsltId = this.vCnsltId // 컨설턴트ID
        pParams.cnsltNm = this.vCnsltNm // 컨설턴트명

        // 파라미터 들고 이동
        this.$emit('onPopupConfirm', pParams)
      }

    }
  };
</script>